import styled from '@emotion/styled';

import getAlphaHex from '@utils/getAlphaHex';

export const StyledPage = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 2em;
  padding: 2em;
  background: ${(props) => props.theme.color.primary600};
`;

export const StyledBrandSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .header {
    height: 5em;
    width: 100%;
    svg {
      height: 100%;
    }
  }

  .center {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tagline {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2em;
      text-transform: uppercase;

      .glow-dot {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        box-shadow:
          inset 0 0 10px 2px
            ${(props) => getAlphaHex(props.theme.color.accent700, 50)},
          0 0 10px 2px ${(props) => props.theme.color.accent300};
        background: ${(props) => getAlphaHex(props.theme.color.gray50, 80)};
      }
    }
  }

  .footer {
    height: 5em;
    width: 100%;
    display: flex;
    justify-content: space-between;

    svg {
      height: 100%;
    }
  }
`;

export const StyledFormSection = styled.div`
  background: ${(props) => props.theme.color.primary100};
  padding: 3em;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .login-form {
    display: flex;
    flex-direction: column;
    gap: 5em;
    font-weight: 600;

    .form-header {
      h1 {
        color: ${(props) => props.theme.color.gray300};
      }

      p {
        color: ${(props) => props.theme.color.gray50};

        a {
          color: ${(props) => props.theme.color.accent700};
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 2em;

      .login-error-message {
        color: ${(props) => props.theme.color.danger300};
      }
    }

    .ant-form-item {
      &.ant-form-item-with-help {
        margin: 1em 0;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }

        .ant-form-item-explain.ant-form-item-explain-connected {
          font-size: 0.9em;
        }
      }
      .ant-form-item-control {
        gap: 0.2em;
      }

      .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;

        input,
        .ant-input-status-error {
          border: none;
          background: transparent;
          border-bottom: 1px solid ${(props) => props.theme.color.gray100};
          color: ${(props) => props.theme.color.gray300};
          padding-left: 0;

          &:hover {
            border: none;
            background: transparent;
            border-bottom: 1px solid ${(props) => props.theme.color.gray100};
          }

          &:focus {
            box-shadow: none;
          }
        }

        .ant-input-password {
          border: none;
          background: transparent;
          border-bottom: 1px solid ${(props) => props.theme.color.gray100};
          color: ${(props) => props.theme.color.gray300};
          padding-left: 0;

          &.ant-input-affix-wrapper-focused {
            box-shadow: none;
          }

          &:hover {
            border: none;
            background: transparent;
            border-bottom: 1px solid ${(props) => props.theme.color.gray100};
          }

          input {
            border: none;
            background: transparent;
          }

          .ant-input-suffix .ant-input-password-icon {
            color: inherit;
          }
        }

        /* Checkbox */
        .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-in-form-item {
          &:hover {
            .ant-checkbox {
              &.ant-checkbox-checked {
                &:after {
                  border-color: ${(props) => props.theme.color.gray700};
                }
              }
            }
          }
        }
        .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
          color: ${(props) => props.theme.color.gray50};

          .ant-checkbox {
            .ant-checkbox-inner {
              background: transparent;
              border-color: ${(props) => props.theme.color.gray700};

              &:after {
                border-color: ${(props) => props.theme.color.accent700};
              }
            }
          }
        }

        .login-form-forgot {
          color: ${(props) => props.theme.color.gray800};
        }
      }

      /* Submit button */
      .login-form-button {
        /* margin-top: 2em; */
        width: 100%;
        background: transparent;
        border: 1px solid ${(props) => props.theme.color.accent700};
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
`;
