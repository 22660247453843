import React from 'react';

// import { isLoggedIn, loginUser } from '@utils/getAccessToken';
import { Button, Checkbox, Form, Input } from 'antd';
import Image from 'next/legacy/image';
// import { useRouter } from 'next/router';

import KoiReaderLogo from '@assets/KoiReaderLogo.svg';
import FooterDesign from '@assets/Login/FooterDesign.svg';
import Infographic from '@assets/Login/Infographic.png';
import Loader from '@components/elements/Loader';

import {
  StyledBrandSection,
  StyledFormSection,
  StyledPage,
} from './styledComponents';

const LoginPage = () => {
  // On initial page loading, it needs to be checked if the user is already logged in and redirect accordingly - hence a loader is displayed
  const [
    loading,
    //  setLoading
  ] = React.useState(true);
  // Error message
  const [loginErrorMessage, setLoginErrorMessage] = React.useState('');

  // const router = useRouter();

  // Login form handler
  const handleSubmit = async () =>
    //values: any
    {
      // Reset error message
      setLoginErrorMessage('');

      // const isAlreadyLoggedIn = await isLoggedIn();
      // if (isAlreadyLoggedIn) {
      //   // TODO: add a modal UI for user to continue previous session or create new
      //   alert('A user is already logged in');
      //   router.push('/');
      //   return;
      // }

      // Construct payload for login
      // const data = {
      //   // Note: value of email should go in lower case, as it is case sensitive in the backend
      //   email: (values.email as string).toLowerCase(),
      //   password: values.password,
      // };

      // Login the user
      // const isAuthenticated = await loginUser(data);

      // // Successful login
      // if (isAuthenticated) {
      //   // Redirect to root after successful authentication
      //   router.push('/');
      //   return;
      // }
      // Login failure
      // TODO: get string from localization - login.invalidLogin
      setLoginErrorMessage('Incorrect email or password');
    };

  // React.useEffect(() => {
  //   (async () => {
  //     // Get logged in state
  //     const loggedIn = await isLoggedIn();
  //     if (loggedIn) {
  //       // Redirect to root
  //       router.push('/');
  //       return;
  //     }
  //     // Display login page
  //     setLoading(false);
  //   })();
  // }, [router]);

  return loading ? (
    // Loader
    <Loader loading={loading} />
  ) : (
    // Login page
    <StyledPage>
      {/* Brand design - infographics section */}
      <StyledBrandSection>
        {/* Header */}
        <div className="header">
          <KoiReaderLogo />
        </div>

        <div className="center">
          {/* Infographic */}
          <Image src={Infographic} alt="" priority />

          {/* Tagline */}
          <div className="tagline">
            Innovate
            <span className="glow-dot" />
            Create
            <span className="glow-dot" />
            Automate
          </div>
        </div>

        {/* Footer - design decoration elements */}
        <div className="footer">
          <FooterDesign />
          <FooterDesign />
        </div>
      </StyledBrandSection>

      {/* Login form section */}
      <StyledFormSection>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          {/* Header */}
          <div className="form-header">
            <h1>Log In</h1>
            {/* Sign-up URL - currently disabled (hidden) */}
            {/* <p>
              New User?{' '}
              <a
                href={
                  process.env.NEXT_PUBLIC_BACKEND_HTTP_URL +
                  '/auth/local/signup'
                }
              >
                Sign Up
              </a>
            </p> */}
          </div>

          {/* Form items */}
          <div className="wrapper">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input a valid Email',
                  type: 'email',
                },
              ]}
            >
              <Input placeholder="Email" name="email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password' },
              ]}
            >
              <Input.Password placeholder="Password" name="password" />
            </Form.Item>
            {/* Login error message */}
            {loginErrorMessage && (
              <Form.Item>
                <Form.ErrorList
                  errors={[loginErrorMessage]}
                  className="login-error-message"
                />
              </Form.Item>
            )}
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              {/* Forgot password page URL - currently redirects to wicked's forgot password page */}
              <a
                className="login-form-forgot"
                href={`${process.env.NEXT_PUBLIC_WICKED_HTTP_URL}/auth/local/forgotpassword?source=${window.location.href}`}
              >
                Forgot password?
              </a>
            </Form.Item>
          </div>

          {/* Submit */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="large"
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
      </StyledFormSection>
    </StyledPage>
  );
};

export default LoginPage;
